@charset "utf-8";
//NanumSquareRound
// @font-face {
//     font-family: "NanumSquareRound";
//     font-style: normal;
//     font-weight: 600;
//     src: url("./assets/fonts/eot/NanumSquareRoundEB.eot");
//     src: url("./assets/fonts/woff/NanumSquareRoundEB.woff") format("woff"), url("./assets/fonts/eot/NanumSquareRoundEB.eot?#iefix") format("embedded-opentype");
// }

// @font-face {
//     font-family: "NanumSquareRound";
//     font-style: normal;
//     font-weight: 500;
//     src: url("./assets/fonts/eot/NanumSquareRoundB.eot");
//     src: url("./assets/fonts/woff/NanumSquareRoundB.woff") format("woff"), url("./assets/fonts/eot/NanumSquareRoundB.eot?#iefix") format("embedded-opentype");
// }

// @font-face {
//     font-family: "NanumSquareRound";
//     font-style: normal;
//     font-weight: 200;
//     src: url("./assets/fonts/eot/NanumSquareRoundR.eot");
//     src: url("./assets/fonts/woff/NanumSquareRoundR.woff") format("woff"), url("./assets/fonts/eot/NanumSquareRoundR.eot?#iefix") format("embedded-opentype");
// }

// @font-face {
//     font-family: "NanumSquareRound";
//     font-style: normal;
//     font-weight: 100;
//     src: url("./assets/fonts/eot/NanumSquareRoundL.eot");
//     src: url("./assets/fonts/woff/NanumSquareRoundL.woff") format("woff"), url("./assets/fonts/eot/NanumSquareRoundL.eot?#iefix") format("embedded-opentype");
// }
//Pretendard
@font-face {
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 100;
    src: url("./assets/fonts/eot/Pretendard-Light.eot");
    src: url("./assets/fonts/woff/Pretendard-Light.woff") format("woff"), url("./assets/fonts/eot/Pretendard-Light.eot?#iefix") format("embedded-opentype");
}

@font-face {
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 200;
    src: url("./assets/fonts/eot/Pretendard-Regular.eot");
    src: url("./assets/fonts/woff/Pretendard-Regular.woff") format("woff"), url("./assets/fonts/eot/Pretendard-Regular.eot?#iefix") format("embedded-opentype");
}

@font-face {
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 300;
    src: url("./assets/fonts/eot/Pretendard-Medium.eot");
    src: url("./assets/fonts/woff/Pretendard-Medium.woff") format("woff"), url("./assets/fonts/eot/Pretendard-Medium.eot?#iefix") format("embedded-opentype");
}

@font-face {
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    src: url("./assets/fonts/eot/Pretendard-SemiBold.eot");
    src: url("./assets/fonts/woff/Pretendard-SemiBold.woff") format("woff"), url("./assets/fonts/eot/Pretendard-SemiBold.eot?#iefix") format("embedded-opentype");
}

@font-face {
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 500;
    src: url("./assets/fonts/eot/Pretendard-Bold.eot");
    src: url("./assets/fonts/woff/Pretendard-Bold.woff") format("woff"), url("./assets/fonts/eot/Pretendard-Bold.eot?#iefix") format("embedded-opentype");
}

/* reset */
html,
body,
div,
span,
object,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
a,
button,
abbr,
address,
img,
q,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
footer,
header,
section,
summary {
    margin: 0;
    padding: 0;
    border: 0;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
} /* HTML5 display-role reset for older browsers */
body,
html {
    width: 100%;
    height: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
}
ol,
ul {
    list-style: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
img {
    border: none;
}
a {
    text-decoration: none;
    color: inherit;
}
address {
    font-style: normal;
}
button {
    display: inline-block;
    border: none;
    background-color: transparent;
    cursor: pointer;
}
input,
button,
a,
select,
option {
    font-size: inherit;
}
input[type="checkbox"] {
    border: none !important;
} /* for lte IE10 */
input[type="radio"] {
    border: none !important;
} /* for lte IE10 */
input[type="text"]::-ms-clear {
    display: none;
}
caption,
hr {
    display: none;
}
pre {
    word-break: break-all;
    white-space: pre-line;
}
li {
    list-style: none;
}
input,
select {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}
/* common */
body,
html {
    width: 100%;
    min-height: 100%;
    //  font-family: "Pretendard", "맑은 고딕", "Malgun Gothic", Dotum, sans-serif;
    font-size: 10px; // 10px = 1rem 단위로 사용하기 위해서 설정
    letter-spacing: -0.0078rem !important;
    color: #333;
    background: #fff;
}
* {
    font-family: "Pretendard", "맑은 고딕", "Malgun Gothic", Dotum, sans-serif;
    font-size: 1.4rem; // 기본 글자 폰트 설정 - 14px
    font-weight: 200;
}
.clearFix:after {
    content: "";
    display: block;
    clear: both;
}
.hidden {
    display: block;
    margin: 0;
    padding: 0;
    width: 0;
    height: 0;
    overflow: hidden;
    font-size: 0;
    line-height: 0;
    visibility: hidden;
}
.txt-center {
    text-align: center;
}
.pos-r {
    position: relative;
}
.va-t {
    vertical-align: top;
}
b,
h1,
h2,
h3,
h4,
h5,
h6,
strong {
    font-weight: normal;
}

/* 스크롤테마 */
.cScroll {
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: thin;
    scrollbar-color: #303030 transparent;
    scrollbar-arrow-color: #fff;
    scrollbar-3dlight-color: #fff;
    scrollbar-darkshadow-color: #fff;
    scrollbar-face-color: #303030;
    scrollbar-hightlight-color: #fff;
    scrollbar-shadow-color: #fff;
    scrollbar-track-color: #fff;
    scrollbar-base-color: #efefef;
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    } //스크롤바 두께
    &::-webkit-scrollbar-button {
        display: none;
    } //위 아래버튼
    &::-webkit-scrollbar-track {
        background-color: rgba(0, 0, 0, 0.2);
        border-radius: 10px;
    } // 스크롤 뒷배경색
    &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.4);
        border-radius: 10px;
    } // 스크롤바
    &::-webkit-scrollbar-thumb:hover {
        background: #929292;
    } // 스크롤바 마우스오버 색
    &::-webkit-scrollbar-thumb:active {
        background: #808080;
    } // 스크롤바 누르고있을 때 색
    &.cScrollX {
        overflow-y: hidden;
        overflow-x: scroll;
        .innerScroll {
            width: auto;
        }
    }
    &.cScrollXY {
        overflow-y: scroll;
        overflow-x: scroll;
    }
}

#root {
    height: 100%;
}
#wrap {
    width: 100%;
    height: 100%;
}

#container {
    width: 1366px;
    height: calc(100% - 40px);
    min-height: 740px;
    display: flex;
    margin: 0 auto;
    padding-top: 40px;
}
#content {
    background: #fff;
    flex: 1;
    border-radius: 0 34px 0 0;
    overflow: auto;
    padding: 32px 30px 52px 0;
    > .inner {
        overflow-y: scroll;
        height: calc(100% - 20px);
        padding: 20px 35px 0 71px;
    }
}

.noDrag {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

// flex 속성
.flex {
    display: flex;
}
.flwrap {
    flex-wrap: wrap;
}
.dir_column {
    flex-direction: column;
}
.ai_center {
    align-items: center;
}
.jc_center {
    justify-content: center;
}
.jc_between {
    justify-content: space-between;
}
.jc_around {
    justify-content: space-around;
}
.jc_evenly {
    justify-content: space-evenly;
}

// date-picker
.rangePicker {
    //  div:last-child:not(.ep-arrow) {
    //      position: fixed !important;
    //  }

    // 캘린더의 화살표
    .ep-arrow.rmdp-ep-arrow {
        display: none;
        // 캘린더 위치 (스크롤 영역 밖으로 보이게)
        & + div {
            position: fixed !important;
        }
    }
    .rmdp-wrapper {
        box-shadow: none;
        border: 2px solid #000000;
        border-radius: 12px;
    }
    .rmdp-calendar {
        width: 278px;
        padding: 0;
        box-sizing: border-box;
    }
    .rmdp-day-picker {
        padding: 14px 20px;
        flex-direction: column;
    }

    .rmdp-header {
        margin: 0;
        padding: 10px 20px 8px 20px;
        border-bottom: 2px solid #000000;
        .rmdp-header-values {
            display: flex;
            flex-direction: row-reverse;
            span {
                font-size: 1.6rem;
                font-weight: 400;
            }
        }
    }
    .rmdp-week {
        &:not(:last-child) {
            margin-bottom: 5px;
        }
        .rmdp-today {
            display: flex;
            justify-content: center;
            span {
                background-color: #eaf4ff;
                color: #000000;
            }
            &::after {
                content: "";
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background-color: #3391ff;
                position: absolute;
                bottom: -7px;
            }
        }
        .rmdp-week-day {
            color: #645349;
            font-size: 1.4rem;
            font-weight: 300;
        }
        .rmdp-day {
            font-size: 1.4rem;
            color: #656565;
            font-weight: 300;
            background: transparent;
            box-shadow: none;
            &.rmdp-range {
                background: #fdf4bd;
                color: #000000;
                &.start,
                &.end {
                    span {
                        background: #fdf4bd;
                        color: #000000;
                    }
                }
            }
            &.rmdp-disabled {
                color: #8798ad;
                span {
                    pointer-events: none; // hover등 취소
                }
            }
            &.rmdp-day-hidden {
                span {
                    pointer-events: none; // hover등 취소
                }
            }
            span {
                position: static;
                width: 100%;
                height: 100%;
                &:hover {
                    background: #fdf4bd;
                    color: #000000;
                }
            }
        }
    }
    .rmdp-arrow-container {
        &.rmdp-left,
        &.rmdp-right {
            display: flex;
            align-items: center;
            width: 30px;
            height: 30px;
            margin: 0;
            padding: 0;
            transition: 0.3s;
            i {
                width: 6px;
                height: 6px;
                margin: 0;
                padding: 0;
            }
            .rmdp-arrow {
                border-color: #a0a5b1;
            }
            &:hover {
                background-color: #fdf4bd;
                border: none;
                box-shadow: none;
                .rmdp-arrow {
                    border-color: #000000;
                }
            }
            &.disabled {
                pointer-events: none; // hover등 취소
            }
        }
        &.rmdp-left {
            .rmdp-arrow {
            }
        }
        &.rmdp-right {
            .rmdp-arrow {
            }
        }
    }
}

//input 숫자 화살표 삭제
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}
